<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="订单支付"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="mainBox">
      <div class="container">
        <div class="row1">
          <div class="number">{{ plate_number }}</div>
          <div
            class="normal">
            {{ plateNumFormatterCate(cateID,plate_number) }}
          </div>
          <!-- <div
            :class="
              plate_number.length < 8
                ? 'normal'
                : /^N.*/.test(plate_number)
                ? 'normal'
                : 'special'
            "
          >
            {{ plateNumFormatter(plate_number) }}
          </div> -->
        </div>
        <div class="row4 row5">
          <span class="time-tip">入场时间:</span>
          <span class="in-time">{{ start_time | filterTime }}</span>
        </div>
        <div class="row4 row5">
          <span class="time-tip">停车时长:</span>
          <span v-if="parked_time" class="in-time"
            >{{parked_time}}</span
          >
          <span v-else class="in-time"></span>
        </div>
        <div class="row4 row5">
          <span class="time-tip">停车金额:</span>
          <span class="in-time">￥{{ (payablefee / 100).toFixed(2) }}</span>
        </div>
         <div class="row4 row5" v-show="lastPaidTotal>0 || lastDiscoutPure>0">
        <span class="time-tip">场内已优惠金额</span>
        <span class="in-time">￥{{ (lastDiscoutPure  / 100).toFixed(2) }} </span>
      </div>
         <div class="row4 row5" v-show="lastPaidTotal>0 || lastDiscoutPure>0">
        <span class="time-tip">场内已付金额</span>
        <span class="in-time">￥{{( lastPaidTotal  / 100).toFixed(2) }} </span>
      </div>

        <div class="row4 row5" @click="Price1()">
          <span class="time-tip">优惠券:</span>
          <span class="couponPrice"
            >{{ couponPrice1 }}
            <van-icon size="0.16rem" color="#333" name="arrow" />
          </span>
        </div>

        <div class="row4 row5" v-if="escapeOrder > 0">
          <span class="time-tip">欠费订单:</span>
          <span
            @click="openEscapeOrder()"
            class="in-time escapeImg"
            style="display: flex; align-items: center"
            >{{ escapeOrder }}笔

            <van-icon size="0.16rem" color="#333" name="arrow" />
          </span>
        </div>
        <div
          class="commentBox"
          v-if="comment !== '' && comment !== null && comment !== undefined"
        >
          <span class="comment-time-tip">备注:</span>
          <span class="comment-in-time">{{ comment }} </span>
        </div>
        <div class="row4 row6">
          <span class="time-tip">需缴费:</span>
          <span class="price">￥{{ (actually_paid / 100).toFixed(2) }}</span>
        </div>
        <div class="button-row-pay">
          <van-button
            :disabled="
              !isTypeReady ||
              !isVueReady ||
              isPress ||
              (actually_paid > 0 && !code)
            "
            type="info"
            size="large"
            @click="confirmPay"
            >确认支付</van-button
          >
        </div>
      </div>
      <div class="dialog" v-if="visible">
        <div class="dialogBox">
          <div class="dialogHead">
            优惠券
            <img src="../../assets/img/close.svg" @click="closeCouponDialog()">
          </div>
          <ul class="listBox" v-if="list.length != 0">
            <li
              class="dialogList"
              v-for="(item, index) in list"
              :key="index"
              @click.stop="getCoupons(item)"
            >
              <div>
                {{
                  item.type == couponType.money ||
                  item.type == couponType.moneyActual
                    ? '￥'
                    : ''
                }}
                <span>{{ item.all_free ? '全免' : item.value }}</span>
                {{
                  item.all_free
                    ? ''
                    : item.type == couponType.time ||
                      item.type == couponType.timeActual
                    ? '分钟'
                    : item.type == couponType.disBreaks ||
                      item.type == couponType.disBreaksActual
                    ? '折'
                    : item.type == couponType.free
                    ? '张'
                    : ''
                }}
              </div>
              <div></div>
              <div>
                <div>{{ item.typeName }}
                  </div>
                <!-- <div>适用于{{ parkname }}</div> -->
                <div>{{ item.biz_name }}</div>
                <div>领券时间：{{ item.granted_time_hms }}</div>
                <div>失效时间：{{ countUseFulTime(item) }}</div>
              </div>
              <img
                src="../../assets/img/gou1.png"
                v-show="selectid.indexOf(item.id) == -1"
              />
              <img
                src="../../assets/img/gou2.png"
                v-show="selectid.indexOf(item.id) != -1"
              />
            </li>
          </ul>
          <ul class="listBox" v-else>
            <div
              style="
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 24px;
              "
            >
              无优惠劵
            </div>
          </ul>
          <div class="dialogBTN" @click="getPrice">确定</div>
        </div>
      </div>
      <div class="banner" v-if="!isCloseAD">
        <adver urls="744202053102985216"></adver>
      </div>
      <div v-if="!isTypeReady" class="mask_layout" @click.stop></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import * as mqtt from '../../api/mqtt/wsconnect'
import * as common from '../../api/common'
import * as constant from '../../api/constant'
import * as api from '../../api/api'
import * as util from '../../api/util'
import { Toast } from 'vant'
import adver from '../../components/Adver.vue'
export default {
  name: 'pay_money',
  props: [],
  components: {
    adver
  },
  data () {
    return {
      btn_del: false,
      // sid: '',
      parkname: '车场',
      plate_number: '',
      parked_time: '',
      car_type: '',
      gross_price: 0,
      actually_paid: 0,
      payablefee: 0,
      paidfee: 0,
      havePayFee: 0, // 已支付展示
      paidfee_cash: 0,
      start_time: '',
      end_time: '',
      in_time: '',
      hour: '',
      min: '',
      seconds: '',
      sign: '',
      req_order_sn: '',
      local_info: {},
      code: '',
      appidLocal: '', // 当前sid对应的appid
      selectType: [],
      selectid: [],
      selectRuleId: [],
      selectCouponid: [],
      Price: '',
      couponPrice: '',
      couponPrice1: '',
      visible: false,
      couponType: {
        moneyActual: 11, // 减免金额
        timeActual: 12, // 减免时间
        disBreaksActual: 13, // 打折减免
        money: 1, // 减免金额
        time: 2, // 减免时间
        disBreaks: 3, // 打折减免
        free: 4 // 全免
      },
      couponTypeLabel: {
        12: '时长减免券',
        11: '金额减免券',
        13: '折扣减免券',
        4: '单次全免券',
        1: '金额减免券(券面)',
        2: '时长减免券(券面)',
        3: '折扣减免券(券面)'
      },
      list: [
        // {
        //   id: 0,
        //   typeName: '第一券',
        //   biz_name: 'yuen03商户',
        //   granted_time: '2024-12-17',
        //   expire_time: '2024-12-18',
        //   granted_time_hms: '2024-12-28 12:12:12'
        // }
      ],
      isVueReady: false,
      isTypeReady: false,
      isEmqxConnect: false,
      isPress: false,
      escapeOrder: '',
      comment: '',
      pay_channel_platform: '',
      escData: {},
      localInfo: {},
      discount_coupon_ids: [], // 查询时计算费用的优惠券id
      discount_coupon_ids_send: [], // 查询时计算费用的优惠券id 备份
      escDataTotal: '',
      tempBack: {
        expireMillSeconds: 240 * 1000,
        timeout: null,
        timestamp: 0
      },
      saveRouteQueryDataLocal: {},
      isCloseAD: false,
      lastPaidTotal: '', // 已支付金额
      lastDiscoutPure: '', // 已优惠金额
      cateID: '', // 车辆类别
      b_user_selected_coupon: false

    }
  },
  computed: {
    ...mapState({
      plateNumber: state => state.plateNumber.plateNumber,
      channelPath: state => state.channelPath.channelPath,
      adverInfo: state => state.adverInfo.adverInfo,
      payType: state => state.payType.payType,
      sid: state => state.sid.sid,
      saveRouteQueryData: state => state.saveRouteQueryData.saveRouteQueryData,
      appid: state => state.appid.appid,
      escapeInfo: state => state.escapeInfo.escapeInfo
    })
  },
  methods: {
    // 车牌类别格式化 ToDo（目前生产是优先展示了临时车（包括了无牌车）。后续修改）
    plateNumFormatterCate (val, num) {
      let name = ''
      if (/^N.*/.test(num)) {
        name = '无牌车'
      } else if (Number(val) === 2) {
        name = '临时车'
      } else {
        name = '固定车'
      }
      return name
    },
    // 获取关闭广告配置
    queryADConfig () {
      const flag = util.judgeADClose({}, 2, 2)
      this.isCloseAD = flag
    },
    plateNumFormatter (para) {
      return para.length < 8
        ? '常规车'
        : /^N.*/.test(para)
          ? '临时车'
          : '能源车'
    },
    getCoupons (row) {
      console.log('getCoupons', row)
      if (row) {
        const rowType = Number(row.type)
        if (this.selectType.length === 0) {
          this.selectType.push(row.type)
          this.selectCouponid.push(row.coupon_id)
          this.selectid.push(row.id)
          this.selectRuleId.push(row.rule_id)
          if (rowType === this.couponType.money || rowType === this.couponType.time) {
            this.couponPrice = row.value
          } else if (rowType === this.couponType.disBreaks) {
            this.couponPrice1 = row.value + '折'
          } else if (rowType === this.couponType.free) {
            this.couponPrice1 = '全免'
          } else {
            if (row.all_free) {
              this.couponPrice1 = '全免'
            } else {
              if (rowType === this.couponType.moneyActual || rowType === this.couponType.timeActual) {
                this.couponPrice = row.value
              } else if (rowType === this.couponType.disBreaksActual) {
                this.couponPrice1 = row.value + '折'
              }
            }
          }
        } else if (rowType === this.couponType.disBreaks || rowType === this.couponType.free) {
          if (this.selectid.indexOf(row.id) !== -1) {
            this.selectid.forEach((val, index) => {
              if (val === row.id) {
                this.selectid.splice(index, 1)
                this.selectType.splice(index, 1)
                this.selectRuleId.splice(index, 1)
                this.selectCouponid.splice(index, 1)

                this.couponPrice = ''
              }
            })
          } else {
            this.$toast('该类型优惠券无法叠加')
          }
          // eslint-disable-next-line no-mixed-operators
        } else if (rowType === this.couponType.disBreaksActual || row.all_free &&
          (rowType === this.couponType.moneyActual || rowType === this.couponType.timeActual)) {
          if (this.selectid.indexOf(row.id) !== -1) {
            this.selectid.forEach((val, index) => {
              if (val === row.id) {
                this.selectid.splice(index, 1)
                this.selectType.splice(index, 1)
                this.selectRuleId.splice(index, 1)
                this.selectCouponid.splice(index, 1)
                this.couponPrice = ''
              }
            })
          } else {
            this.$toast('该类型优惠券无法叠加')
          }
        } else if (this.selectType.indexOf(row.type) === -1) {
          this.$toast('请选择相同类型的优惠券')
        } else if (this.selectType.indexOf(row.type) !== -1) {
          if (this.selectid.indexOf(row.id) !== -1) {
            this.selectid.forEach((val, index) => {
              if (val === row.id) {
                this.selectid.splice(index, 1)
                this.selectType.splice(index, 1)
                this.selectRuleId.splice(index, 1)
                this.selectCouponid.splice(index, 1)
                this.couponPrice -= row.value
              }
            })
          } else {
            var length = row.allowed_used_onetime_self ? row.allowed_used_onetime_self : 99
            if (this.selectid.length >= length) {
              this.$toast('已超出叠加限制')
            } else {
              this.selectType.push(row.type)
              this.selectid.push(row.id)
              this.selectCouponid.push(row.coupon_id)
              this.selectRuleId.push(row.rule_id)
              this.couponPrice += row.value
            }
          }
        }
        if (this.couponPrice <= 0 && (rowType === this.couponType.money || rowType === this.couponType.time)) {
          this.couponPrice1 = '未使用优惠券'
        } else if (rowType === this.couponType.money || (!row.all_free && rowType === this.couponType.moneyActual)) {
          this.couponPrice1 = '-' + Number(this.couponPrice).toFixed(2) + '元'
        } else if (rowType === this.couponType.time || (!row.all_free && rowType === this.couponType.timeActual)) {
          this.couponPrice1 = '-' + this.couponPrice + '分钟'
        }
      } else {
        this.$toast.fail('获取券值出错了')
      }
    },
    unsubscribe: function (action, callback) {
      // 订阅
      const topic = `/ypt/u_${this.sid}/${encodeURIComponent(this.plate_number)}`
      const options = {
        payload: {
          topic: action,
          plate_number: this.plate_number
        }
      }
      mqtt.unsubscribe(topic, options, function (err, granted) {
        if (err) {
          callback(err, granted)
          return
        }
        callback(null, granted)
      })
    },
    getTempBackTimeout () {
      const ms = this.tempBack.expireMillSeconds
      if (!this.tempBack.timestamp) this.tempBack.timestamp = new Date().getTime()
      // console.log('tempBack.timestamp', this.tempBack.timestamp)
      this.tempBack.timeout = setTimeout(() => {
        this.expireGoBack()
        this.clearBackTimeout()
      }, ms)
    },
    clearBackTimeout () {
      clearTimeout(this.tempBack.timeout)
      this.tempBack.timeout = null
    },
    expireGoBack () {
      this.tempBack.timestamp = 0
      this.$dialog.alert({
        // showCancelButton: true,
        title: '支付超时',
        message: '点击“确认”后重新付款'
      }).then(() => {
        this.onClickLeft()
      })
    },
    confirmPay: async function () {
      if (new Date().getTime() - this.tempBack.timestamp > this.tempBack.expireMillSeconds) {
        this.clearBackTimeout()
        return this.expireGoBack()
      }
      this.isPress = true
      if (parseFloat(this.gross_price) <= 0) {
        this.$toast('收费 0 元')
        // return
      }
      const that = this
      // let payType = this.$Storejs.getItem('payType');
      try {
        // if (payType == common.payType.wx || payType == common.payType.zfb) {
        //   //直连支付、农行微信支付
        //   if (that.actually_paid > 0) {
        //     if (!this.code) {
        //       this.getAllCode()
        //       return
        //     }
        //   }
        // // }
        // if (payType == common.payType.abc) { // 农行微信支付
        //   if (that.actually_paid > 0) {
        //     if (!this.code) {
        //       this.getAllCode()
        //       return
        //     }
        //   }
        // }
        // 除了支付为0元，所有支付都要获取code
        if (that.actually_paid > 0) {
          if (!this.code) {
            this.getAllCode()
            return
          }
        }

        if (!this.isVueReady) {
          return this.$toast('努力加载中.')
        } else if (!this.isTypeReady) {
          if (common.getUserAgent() === 'wx') {
            return this.$toast('微信努力加载中.')
          } else {
            return this.$toast('支付宝努力加载中.')
          }
        } else if (!this.isEmqxConnect) {
          const client = await this.init_mqtt()

          if (client && client.connected) {
            await this.subscribe('sq')
            doPay()
            return
          } else {
            throw (new Error('网络中断...'))
          }
        }
        doPay()
      } catch (e) {
        console.log(e)
        this.$toast.fail('请求失败')
      }

      function doPay () {
        that.$toast.loading({
          mask: true,
          message: '请稍候...',
          duration: 5000
        })
        that.local_info.discount_coupon_ids = that.selectCouponid
        that.local_info.discount_rule_id = that.selectRuleId
        that.local_info.discount_rule_type = that.selectType[0]
        const obj = {
          sid: that.sid,
          gross_price: parseFloat(that.gross_price),
          actually_paid: parseFloat(that.actually_paid),
          // req_order_sn: that.req_order_sn,
          sign: that.sign,
          code: that.code || 'invalid',
          plate_number: that.plate_number,
          subject: (that.parkname ? that.parkname + ' ' : '') + that.plate_number + '停车收费',
          start_time: that.start_time,
          end_time: that.end_time,
          local_info: that.local_info,
          is_xcx: false
        }
        common.getPayargs(obj, null, that.payType, that.local_info.pay_channel_platform)
          .then(payargs => {
            that.$toast.clear()
            that.selectid = []
            that.selectType = []
            that.selectRuleId = []
            if (parseFloat(that.actually_paid) <= 0) {
              that.isPress = true
              that.$toast({
                type: 'success',
                message: '支付0元',
                duration: 3000,
                forbidClick: true
              })
              setTimeout(function () {
                if (common.getUserAgent() === 'wx') {
                  // eslint-disable-next-line no-undef
                  WeixinJSBridge.call('closeWindow')
                } else if (common.getUserAgent() === 'zfb') {
                  // eslint-disable-next-line no-undef
                  ap.popWindow()
                }
              }, 3000)
            } else {
              that.isPress = false
              if (common.getUserAgent() === 'wx') {
                that.wxReadyToPay(payargs)
              } else if (common.getUserAgent() === 'zfb') {
                that.do_zfb_pay(payargs)
              }
            }
          })
          .catch(e => {
            that.$toast.clear()
            that.isPress = false
            if (parseFloat(that.actually_paid) > 0) {
              console.log(e)
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败',
                message: e.msg
              }).then(() => {
                that.onClickLeft()
              })
            } else that.$toast.clear()
          })
      }
    },
    ZFBReady (callback) {
      if (window.AlipayJSBridge) {
        callback && callback()
      } else {
        if (document.addEventListener) {
          document.addEventListener('AlipayJSBridgeReady', callback, false)
        } else if (document.attachEvent) {
          document.attachEvent('AlipayJSBridgeReady', callback)
        }
      }
    },
    do_zfb_pay (payargs) {
      const that = this
      this.ZFBReady(function () {
        const payType = that.payType
        if (payType === common.payType.zfb) { // 支付宝直联
          // eslint-disable-next-line no-undef
          ap.tradePay({
            tradeNO: payargs.tradeNo
          }, function (res) {
            if (res && (res.resultCode === '9000' || res.resultCode === 9000)) {
              that.isPress = true
              that.doCloseAll()
              if (common.adverType === '2') {
                // eslint-disable-next-line no-undef
                AlipayJSBridge.call('pushWindow', {
                  url: 'https://bo111.yuntingiot.com/api/ac/477786592149311488',
                  param: {
                    backBehavior: 'pop',
                    closeCurrentWindow: true
                  }
                })
              }
            } else {
              that.$dialog.alert({
                title: '支付失败'
              }).then(() => {
                // ToDo 暂时将支付宝失败时关闭应用处理，后续优化
                // that.onClickLeft()
                that.doCloseAll()
              })
            }
          })
        } else if (payType === common.payType.bjyz_zfb) { // 支付宝云纵间联
          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          console.log('zfb 云纵', pars)
          that.$store.commit(constant.keyAdverInfoVuex, pars)
          that.isPress = true
          window.location.replace(payargs.trade_url)
        } else if (payType === common.payType.pp_zfb) { // 支付宝PP间联
          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          console.log('zfb pp', pars)
          that.$store.commit(constant.keyAdverInfoVuex, pars)
          window.location.replace(payargs.pay_url)
        } else if (payType === common.payType.abc_zfb) { // 支付宝农行间联
          if (payargs.tradeNo) {
            // eslint-disable-next-line no-undef
            ap.tradePay({
              tradeNO: payargs.tradeNo
            }, function (res) {
              if (res && (res.resultCode === '9000' || res.resultCode === 9000)) {
                that.isPress = true
                that.doCloseAll()
                if (common.adverType === '2') {
                // eslint-disable-next-line no-undef
                  AlipayJSBridge.call('pushWindow', {
                    url: 'https://bo111.yuntingiot.com/api/ac/477786592149311488',
                    param: {
                      backBehavior: 'pop',
                      closeCurrentWindow: true
                    }
                  })
                }
              } else {
                that.$dialog.alert({
                  title: '支付失败'
                }).then(() => {
                // ToDo 暂时将支付宝失败时关闭应用处理，后续优化
                // that.onClickLeft()
                  that.doCloseAll()
                })
              }
            })
          } else {
            const pars = {
              start_time: that.start_time,
              hour: that.hour,
              min: that.min,
              seconds: that.seconds,
              actually_paid: that.actually_paid,
              couponPrice1: that.couponPrice1,
              invoice_url: payargs.invoice_url,
              sid: that.sid,
              parkName: that.parkName,
              plateNumber: that.plate_number
            }
            console.log('zfb 农行', pars)
            that.$store.commit(constant.keyAdverInfoVuex, pars)

            window.location.replace(payargs.pay_url)
          }
        } else if (payType === common.payType.lanzhoubank_zfb) { // 支付宝兰州间联
          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            // invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          that.$store.commit(constant.keyAdverInfoVuex, pars)

          window.location.replace(payargs.billQRCode)
        } else if (payType === common.payType.ningbobank_zfb) { // 支付宝宁波银行间联
          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            // invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          console.log('zfb 宁波银行', pars)
          that.$store.commit(constant.keyAdverInfoVuex, pars)

          window.location.replace(payargs.pay_url)
        }
      })
    },
    async get_open_url (payWay, sid, plateNumber, tempQueryParams) {
      //          const local = 'https://paycloud.test.szzcloud.club/#/pay_money'
      const local = common.cloudPayUrl + '/#/payMoney'
      return axios({
        url: common.cloudPayUrl + '/api/' + api.urlList.get_openid_url,
        method: 'POST',
        data: {
          pay_way: payWay,
          sid: sid,
          plate_number: plateNumber,
          tempQueryParams: tempQueryParams,
          redirect_url: local
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        const d = res && res.data
        if (d.code === 10000) {
          // console.log('get_open_url', d)
          this.$store.commit(constant.keyPayTypeVuex, d.pay_type)

          return d.open_url
        } else {
          return Promise.reject(new Error(res.msg))
        }
      })
    },
    doCloseAll () {
      if (common.getUserAgent() === 'wx') {
        // this.closeWindowWX()
      } else if (common.getUserAgent() === 'zfb') {
        this.closeWindowZfb()
      }
    },
    closeWindowZfb () {
      if (window.AlipayJSBridge) {
        close()
      } else {
        if (document.addEventListener) {
          document.addEventListener('AlipayJSBridgeReady', close(), false)
        } else if (document.attachEvent) {
          document.attachEvent('AlipayJSBridgeReady', close())
        }
      }
      function close () {
        // eslint-disable-next-line no-undef
        AlipayJSBridge.call('closeWebview')
      }
    },
    onClickLeft () {
      this.$router.replace({
        path: '/index',
        query: {
          sid: this.sid,
          channel_path: this.channelPath
        }
      })
    },
    async getAppId () {
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_wx_appid,
        method: 'POST',
        data: {
          sid: this.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        const d = res && res.data
        if (d.code === 10000) {
          // console.log('getAppId', d)
          this.appidLocal = d.app_id
          this.$store.commit(constant.keyAppIDVuex, d.app_id)
          return this.appidLocal
        } else {
          return Promise.reject(new Error())
        }
      })
    },
    async getZFBAppId () {
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_zfb_appid,
        method: 'POST',
        data: {
          sid: this.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        const d = res && res.data
        if (d.code === 10000) {
          this.appidLocal = d.auth_app_id
          this.$store.commit(constant.keyAppIDVuex, d.app_id)

          return this.appidLocal
        } else {
          return Promise.reject(new Error())
        }
      })
    },

    onBridgeReady (payargs) {
      var that = this

      const payType = this.payType
      if (payType === common.payType.wx) { // 微信直联
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: payargs.appId != null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType != null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // that.$toast.success('支付完成')
              // // that.$router.replace('/index')
              // that.$router.replace({
              //   path: '/index',
              //   query:{
              //     sid: this.$Storejs.getItem('sid'),
              //     channel_path: this.$Storejs.getItem('channel_path')
              //   }
              // })
              // that.$toast({
              //   type: 'success',
              //   message: '支付完成',
              //   duration: 0,
              //   forbidClick: true
              // })
              window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败'
              }).then(() => {
                that.onClickLeft()
              })
            }
          })
      } else if (payType === common.payType.bjyz) { // 微信云纵间联
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          invoice_url: payargs.invoice_url,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }
        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.trade_url)
        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.pp) { // 微信PP间联
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.pay_url)
        // pay_url: "https://auth.4pyun.com/authorize?scope=snsapi_base&redirect_uri=https%3A%2F%2Fapp.4pyun.com%2Fpayment%2Ftrade%2Fcreate%3Fpay_id%3D983768955900399617"

        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.abc) { // 微信农行间联
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        that.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true

        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: payargs.appId != null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType != null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // that.$toast.success('支付完成')
              // // that.$router.replace('/index')
              // that.$router.replace({
              //   path: '/index',
              //   query:{
              //     sid: this.$Storejs.getItem('sid'),
              //     channel_path: this.$Storejs.getItem('channel_path')
              //   }
              // })
              // that.$toast({
              //   type: 'success',
              //   message: '支付完成',
              //   duration: 0,
              //   forbidClick: true
              // })
              window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败'
              }).then(() => {
                that.onClickLeft()
              })
            }
          })
      } else if (payType === common.payType.lanzhoubank) { // 兰州银行微信
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        that.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.billQRCode)
      } else if (payType === common.payType.ningbobank) { // 宁波银行微信
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.pay_url)
      }
    },
    wxReadyToPay (payargs) {
      var that = this
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          // console.log('vv 111')
          document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(payargs), false)
        } else if (document.attachEvent) {
          // console.log('vv 222')
          document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(payargs))
          document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(payargs))
        }
      } else {
        // console.log('vv 333')
        that.onBridgeReady(payargs)
      }
    },
    strToDate (dateObj) {
      if (dateObj) {
        dateObj = dateObj.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').replace(/(-)/g, '/')
        if (dateObj.indexOf('.') > 0) dateObj = dateObj.slice(0, dateObj.indexOf('.'))
        return new Date(dateObj)
      } else {
        return ''
      }
    },
    query_customer_by_sid () {
      axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_customer,
        method: 'POST',
        data: {
          sid: this.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        const d = res && res.data
        if (d.code === 10000) {
          this.parkname = d.list[0].alias_name || '本车场'
        }
      })
    },
    Price1 () {
      // this.visible=true
      console.log('点击了优惠券', this.channelPath)
      if (Number(this.channelPath) !== -1) {
        if (!/^N.*/.test(this.plate_number)) {
          // this.$toast('有牌车通道码不支持优惠券')
          // return
        }
        //  允许无牌车通道码选择优惠券
        // console.log('QQQQQ')
      }

      this.$toast.loading({
        forbidClick: true,
        message: '请稍候...',
        duration: 3000
      })
      const price1 = true
      this.get_coupon(price1)
    },
    countUseFulTime (item) {
      if (!item.expire_time) return ''
      if (new Date(item.expire_time).getFullYear() >= 2999) {
        return '长期'
      } else {
        return common.format(item.expire_time, 'yyyy-M-dd hh:mm:ss')
      }
    },
    async get_coupon (visible) {
      console.log('get_coupon', visible)
      var para = {
        v: '1.0',
        app_key: 'd7ca0abc-6b4b-44e4-bc71-7fd329ce2f7a',
        app_pass: '17f6e649-d26f-488f-b03b-5676aa3be362',
        sid: this.sid,
        vh_p_number: this.plate_number,
        page_no: 1,
        page_size: 1000,
        status: 1
      }
      var res = await axios({
        url: common.codeUrl + 'enterprise.coupon.list_by_vh',
        method: 'POST',
        data: para,
        header: {
          'content-type': 'application/json' // 默认值
        }
      })
      this.$toast.clear()
      console.log('get_coupon---', res)
      if (res.data.code && res.data.code === 10000) {
        if (res.data.list && res.data.list.length) {
          if (visible === true) {
            // this.$toast()
            // this.$toast.close();

            this.visible = true
          }
          res.data.list.forEach((val, index) => {
            val.typeName = this.couponTypeLabel[val.type]
            if (val.all_free) {
              val.typeName += '(全免)'
            }
            if (val.type === this.couponType.money ||
              val.type === this.couponType.moneyActual) {
              val.value /= 100
            } else if (val.type === this.couponType.time) {
            } else if (val.type === this.couponType.disBreaks ||
              val.type === this.couponType.disBreaksActual) {
              val.value /= 10
            } else if (val.type === this.couponType.free) {
            }
            // val.expire_time = common.format(val.expire_time, 'yyyy-M-dd')
            val.granted_time_hms = common.format(val.granted_time, 'yyyy-M-dd hh:mm:ss')
            // val.granted_time = common.format(val.granted_time, 'yyyy-M-dd')
            // if(index == 0){
            //   this.selectType.push(val.type)
            //   this.selectid.push(val.coupon_id)
            //   this.selectRuleId.push(val.rule_id)
            //   if(val.type == this.couponType.money){
            //     this.couponPrice1 = '-' + val.value + '元'
            //   }else if(val.type == this.couponType.time){
            //     this.couponPrice1 = '-' + val.value + '分钟'
            //   }else if(val.type == this.couponType.disBreaks){
            //     this.couponPrice1 = val.value + '折'
            //   }else if(val.type == this.couponType.free){
            //     this.couponPrice1 = '全免'
            //   }
            // }
          })
          this.$nextTick(() => {
            console.log('优惠券列表', res.data.list)
            this.list = res.data.list
            if (!visible) {
              // 判断是否为通道付 是的话 根据收费信息中的优惠券列表来勾选对应的优惠券
              console.log('这是通道付时计算费用包含的优惠券id', this.discount_coupon_ids)
              if (this.discount_coupon_ids.length > 0) {
                // 遍历列表，检查计算费用的 coupon_id 是否在 discount_coupon_ids 中
                const isAnyCouponUsed = this.list.some(item =>
                  this.discount_coupon_ids.includes(item.coupon_id)
                )
                if (!isAnyCouponUsed) {
                  this.couponPrice1 = '未使用优惠券'
                } else {
                  this.list.forEach(item => {
                    if (this.discount_coupon_ids.includes(item.coupon_id)) {
                    // 如果匹配到优惠券 ID，则调用 getCoupons 方法
                      this.getCoupons(item)
                    }
                  })
                }
              } else {
                this.couponPrice1 = '未使用优惠券'
              }

              // this.getPrice()
            }
          })
        } else {
          this.couponPrice1 = '未获取到优惠券'
        }
      } else {
        this.$toast.fail('获取优惠券失败，请重试')
      }
      return res
    },
    async queryOrder () { // 查询车牌订单
      var that = this
      // const plateNumber = this.plate_number
      that.$toast.loading({
        mask: true,
        message: '请稍候...',
        duration: 3000
      })
      try {
        await that.init_mqtt()
        await that.subscribe('sq')
      } catch (e) {
        console.log(e)
        // this.$toast.fail(e)
        throw e
      }
    },
    subscribe: async function (action, callback) {
      // 订阅
      const topic = `/ypt/u_${this.sid}/${encodeURIComponent(this.plate_number)}`
      const options = {
        payload: {
          topic: action,
          plate_number: this.plate_number,
          channel_path: this.channelPath || -1
        }
      }
      // console.log('mqtt', mqtt)
      await mqtt.subscribe(topic, options)
    },
    async init_mqtt (cb) {
      var that = this
      // var utils = util
      const config = {
        onConnected: function () {
          that.isEmqxConnect = true
        },
        // onReconnect: that.subscribe,
        onMessage: this.getFQResult
      }

      const client = await mqtt.getClient(config)
      if (client && client.connected) {
        client.callback.fq = this.getFQResult
        return client
      } else {
        that.$toast.fail('NetWork err!')
        throw new Error('client NetWork err!')
      }
    },
    getFQResult: async function (topic, message, packet) {
      console.log('getFQResult123', message)
      // this.$dialog.alert({
      //   title: '支付111',
      //   message: JSON.stringify(message)
      // }).then(() => {
      // })
      const that = this
      if (message instanceof Uint8Array) message = JSON.parse(message.toString()) // 收到的信息
      // console.log('payMessage', message)
      if (message && message.code !== common.resCode.OK) {
        return that.$toast(message.msg || '查询费用出错')
      }
      that.local_info = {
        ...message,
        sid: this.sid,
        plate_number: message.plate_number,
        start_time: message.start_time,
        end_time: message.end_time,
        customer_id: message.customer_id,
        wks_id: message.wks_id,
        operator_id: message.operator_id,
        channel_path: this.channelPath,
        vh_p_number: message.plate_number,
        vh_pnull_number: message.vh_pnull_number,
        state: message.state,
        payablefee: message.payablefee,
        paidfee: message.paidfee,
        havePayFee: message.havePayFee,
        discount: message.discount,
        fee_rule_id: message.fee_rule_id,
        discount_rule_id: message.discount_rule_id,
        vh_in_id: message.vh_in_id,
        vh_out_id: message.vh_out_id,
        parked_time: message.parked_time,
        vh_cate_id: message.vh_cate_id,
        payType: message.payType,
        pay_channel: message.pay_channel,
        fee_value: message.fee_value,
        fee_order_sn: message.fee_order_sn,
        discount_coupon_values: message.discount_coupon_values,
        discount_coupon_sources: message.discount_coupon_sources,
        pay_channel_platform: message.pay_channel_platform
      }
      const plateNumber = that.plate_number // 输入的车牌
      console.log('plateNumber', plateNumber)
      console.log('message.plate_number', message.plate_number)
      console.log('message.fee_value', message.fee_value)
      if (decodeURIComponent(message.plate_number) === plateNumber) {
        that.$toast.clear()
        if (isNaN(Number(message.fee_value)) || message.fee_value == null) {
          that.$toast(message.msg || '计算费用失败')
          // return
        }
        that.actually_paid = message.fee_value
        // TODO 同时更新 停车金额 和停车时长
        that.payablefee = message.payablefee
        // that.parked_time = util.parkTimeformat(message.parked_time)
        const startTime = message.start_time
        const endTime = message.end_time
        this.parked_time = util.countParkTime(startTime, endTime)
        // 更新场内已支付和城内已优惠金额
        this.lastPaidTotal = message.last_paid_total || message.has_paid_fee_value || 0
        this.lastDiscoutPure = message.last_discout_pure || message.has_discount_pure || 0
        // 判断此时的计算费用的优惠券id
        if (message.discount_coupon_ids.length === 0) {
          this.couponPrice1 = '未使用优惠券'
        }
        that.visible = false
      }
    },
    // 用户点击关闭 优惠券弹窗
    closeCouponDialog () {
      // 取消之后，还是按照原来的优惠券进行勾选 不改变已选中的优惠券

      if (this.discount_coupon_ids_send.length > 0) {
        this.selectType = []
        this.selectCouponid = []
        this.selectid = []
        this.selectRuleId = []
        // 比较row的coupon_id是否在this.discount_coupon_ids中 是的话 勾选
        this.list.forEach(item => {
          if (this.discount_coupon_ids_send.includes(item.coupon_id)) {
            this.getCoupons(item)
          }
        })
      } else {
        this.couponPrice1 = '未使用优惠券'
        this.selectType = []
        this.selectCouponid = []
        this.selectid = []
        this.selectRuleId = []
      }
      this.visible = false
    },
    // 用户选择优惠券点击确定
    async getPrice () {
      // 只针对 通道付情况
      if (this.channelPath !== -1) {
      // 确定后 判断 选择的优惠券id和保存的优惠券id是否一致 一致则return 否则重新查询
        const arrSeamFlag = util.areArraysIdentical(this.selectCouponid, this.discount_coupon_ids_send)
        if (arrSeamFlag) {
        // 同时 关闭优惠券弹窗
          this.visible = false
          this.b_user_selected_coupon = false
          return
        }
        this.b_user_selected_coupon = true
      }
      // 确认后，覆盖初始化的优惠券id列表
      this.discount_coupon_ids_send = [...this.selectCouponid]//
      try {
        await this.queryOrder()
      } catch (e) {
        this.$toast.fail('查询费用出错')
        return
      }

      const data = {
        sid: this.sid,
        plate_number: this.plate_number,
        channel_path: this.channelPath,
        discount_coupon_ids: this.selectCouponid,
        version: 'v2',
        b_user_selected_coupon: this.b_user_selected_coupon
      }

      axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.queryPrice,
        method: 'POST',
        data: data,
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        if (res.data.code && res.data.code === 10000) {
          // this.$toast.loading({
          //   mask: true,
          //   message: '请稍候...',
          //   // duration: 10000
          // });
          // mqtt.getClient()
          this.visible = false
        } else {
          this.$toast(common.resMSG[res.data.code])
        }
      })
    },
    WX_Bridge_ready (cb) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', cb(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', cb())
          document.attachEvent('onWeixinJSBridgeReady', cb())
        }
      } else {
        cb()
      }
    },
    getAllCode () {
      // console.log('getAllCode', this.code, this.appid)
      // console.log('getAllCode', !this.code)
      if (this.code) {
        return
      }
      if (common.getUserAgent() === 'wx') {
        // console.log('wx getcode')
        this.getAppId().then(appid => {
          window.location.href = common.getCodeObtainUrl(window.location, appid, true)
          console.log('window.location.href', window.location.href)
        }).catch(e => {
          this.$toast('网络错误')
        })
      } else if (common.getUserAgent() === 'zfb') {
        if (!this.code) {
          this.getZFBAppId().then(appid => {
            window.location.href = common.getCodeObtainUrl(window.location, appid, false)
          }).catch(e => {
            this.$toast('网络错误')
          })
        }
      }
      // console.log('22222222222222')
    },
    // add 点击跳转到欠费订单
    openEscapeOrder () {
      // return false
      this.$router.push({
        path: '/payEscape',
        query: {
          pay_channel: this.pay_channel,
          pay_channel_platform: this.pay_channel_platform,
          sid: this.sid,
          sign: this.sign,
          escData: JSON.stringify(this.escData),
          localInfo: JSON.stringify(this.localInfo)
        }
      })
    },
    unsubscribeAndClose () {
      this.unsubscribe('sq', function (err, granted) {
        console.log('退订', err, granted)
        mqtt.close()
      })
      this.clearBackTimeout()
    }
  },
  filters: {
    filterVH (num) {
      const reg = /^N.*/
      if (reg.test(num)) {

      }
    },
    filterClass (num) {
      const reg = /^N.*/
      if (num.length < 8) {

      }
      if (reg.test(num)) {

      }
    },
    filterTime (time) {
      try {
        if (time) {
          return common.format(time, 'yyyy-MM-dd hh:mm:ss')
        } else {
          return '无入场时间'
        }
      } catch (e) {
        return '不支持的时间格式'
      }
    }
  },
  created () {
    console.log('来到了payMoney created', this.$route.query)
    // 保存初次进来时的query数据 避免页面跳转数据失效
    if (this.$route.query.plate_number !== undefined) {
      this.saveRouteQueryDataLocal = this.$route.query
      // this.$store.commit(constant.keySaveRouteQueryDataVuex, this.saveRouteQueryDataLocal)
      sessionStorage.setItem(constant.keySaveRouteQueryDataVuex, JSON.stringify(this.saveRouteQueryDataLocal))
    }

    // console.log('wx还是支付宝', common.getUserAgent())
    if (common.getUserAgent() === 'wx') {
      this.code = this.$route.query.code
    } else if (common.getUserAgent() === 'zfb') {
      this.code = this.$route.query.auth_code
    }

    const s1 = (new Date(this.strToDate(this.saveRouteQueryDataLocal.start_time || this.saveRouteQueryDataLocal.end_time))).getTime()
    const s2 = (new Date(this.strToDate(this.saveRouteQueryDataLocal.end_time))).getTime()
    // console.log(s1, s2)

    console.log('this.saveRouteQueryDataLocal', this.saveRouteQueryDataLocal)

    const total = (s2 - s1) / 1000
    const hour = parseInt(total / (60 * 60))
    const afterHour = total - hour * 60 * 60 // 取得算出小时数后剩余的秒数
    const min = parseInt(afterHour / 60)
    const seconds = total - hour * 60 * 60 - min * 60

    this.plate_number = this.saveRouteQueryDataLocal.plate_number
    this.$store.commit(constant.keyPlateNumberVuex, this.saveRouteQueryDataLocal.plate_number)
    this.gross_price = this.saveRouteQueryDataLocal.gross_price
    // console.log('this.saveRouteQueryDataLocal.localInfo', this.saveRouteQueryDataLocal.localInfo)
    this.actually_paid = JSON.parse(this.saveRouteQueryDataLocal.localInfo).fee_value
    this.payablefee = JSON.parse(this.saveRouteQueryDataLocal.localInfo).payablefee
    this.paidfee = JSON.parse(this.saveRouteQueryDataLocal.localInfo).paidfee
    this.havePayFee = JSON.parse(this.saveRouteQueryDataLocal.localInfo).havePayFee

    this.comment = JSON.parse(this.saveRouteQueryDataLocal.localInfo).comment
    this.paidfee_cash = JSON.parse(this.saveRouteQueryDataLocal.localInfo).paidfee_cash === undefined ? 0 : JSON.parse(this.saveRouteQueryDataLocal.localInfo).paidfee_cash
    this.req_order_sn = this.saveRouteQueryDataLocal.req_order_sn
    this.start_time = this.saveRouteQueryDataLocal.start_time
    this.end_time = this.saveRouteQueryDataLocal.end_time
    this.sign = this.saveRouteQueryDataLocal.sign
    this.hour = hour
    this.min = min
    this.seconds = seconds
    this.local_info = JSON.parse(this.saveRouteQueryDataLocal.localInfo)
    if (!this.local_info.channel_path) {
      this.local_info.channel_path = -1
    }
    // this.sid = this.sid
    // 初始化时 计算了几个优惠券 则勾选几个优惠券
    this.discount_coupon_ids = JSON.parse(this.saveRouteQueryDataLocal.localInfo).discount_coupon_ids
    this.discount_coupon_ids_send = JSON.parse(this.saveRouteQueryDataLocal.localInfo).discount_coupon_ids
    // this.selectCouponid = JSON.parse(this.saveRouteQueryDataLocal.localInfo).discount_coupon_ids
    this.tempBack.timestamp = this.saveRouteQueryDataLocal.receive_timestamp || new Date().getTime()

    this.lastPaidTotal = JSON.parse(this.saveRouteQueryDataLocal.localInfo).last_paid_total || JSON.parse(this.saveRouteQueryDataLocal.localInfo).has_paid_fee_value || 0
    this.lastDiscoutPure = JSON.parse(this.saveRouteQueryDataLocal.localInfo).last_discout_pure || JSON.parse(this.saveRouteQueryDataLocal.localInfo).has_discount_pure || 0
    this.cateID = JSON.parse(this.saveRouteQueryDataLocal.localInfo).vh_cate_id

    this.init_mqtt().catch(e => {
      Toast.fail('请检查网络,支付查询失败')
    })
    this.query_customer_by_sid()
  },
  mounted () {
    // console.log('@@@@@@@@@@', this.$route.query)
    // console.log('@@@@@@@@@@saveRouteQueryDataLocal', this.saveRouteQueryDataLocal)
    this.queryADConfig()

    this.pay_channel_platform = this.saveRouteQueryDataLocal.pay_channel_platform
    this.sign = this.saveRouteQueryDataLocal.sign

    // this.$store.commit(constant.keySaveRouteQueryDataVuex, this.saveRouteQueryDataLocal)

    // 判断是否有欠费订单和当前支付订单
    // 根据接受参数对象 判断有无欠费订单
    // console.log('this.saveRouteQueryDataLocal.escape_info', this.saveRouteQueryDataLocal.escape_info)
    // console.log('this.saveRouteQueryDataLocal.local_info', this.saveRouteQueryDataLocal.local_info)
    // const escData = this.$route.query.escape_info ? JSON.parse(this.saveRouteQueryDataLocal.escape_info) : null
    const escData = this.escapeInfo ? this.escapeInfo : null
    const localInfo = JSON.parse(this.saveRouteQueryDataLocal.localInfo)
    // console.log('escData', escData)
    // console.log('localInfo', localInfo)
    this.localInfo = localInfo
    // this.sid = localInfo.sid
    this.pay_channel = localInfo.pay_channel
    this.actually_paid = JSON.parse(this.saveRouteQueryDataLocal.localInfo).fee_value
    this.plate_number = this.saveRouteQueryDataLocal.plate_number
    // this.parked_time = util.parkTimeformat(JSON.parse(this.saveRouteQueryDataLocal.localInfo).parked_time)
    const startTime = JSON.parse(this.saveRouteQueryDataLocal.localInfo).start_time
    const endTime = JSON.parse(this.saveRouteQueryDataLocal.localInfo).end_time
    this.parked_time = util.countParkTime(startTime, endTime)

    this.isVueReady = false

    // console.log('欠费订单', escData)
    // const escData = util.parseIfJSONString(escDataLocal)
    // test 欠费订单数量
    // escData.total = 2

    // console.log('欠费订单 last', escData)
    if (this.code) {
      if (typeof escData === 'object' && escData) {
        this.escData = escData
        this.escapeOrder = escData.total || 0

        // // 正常需要支付金额
        // this.actually_paid = 0
        // console.log('escData.total', escData.total)
        // this.escDataTotal = escData.total

        // console.log('escData.total', escData.total)
        // console.log('this.actually_paid', this.actually_paid)

        const that = this

        if (escData.total > 0 && this.actually_paid !== 0) {
        // console.log('121212121')

          this.$nextTick(() => {
            setTimeout(() => {
            // 先正常支付 再补缴欠费
              that.$dialog({
                title: '通知',
                message: `${this.plate_number}有${escData.total}笔欠费订单，请扫码付款后补缴欠费订单离场`,
                confirmButtonColor: '#1989fa'
              // confirmButtonText: '确认'
              }).then(() => {
              }).catch(() => {
              // on cancel
              })
            }, 100)
          })
        } else if (escData.total > 0 && this.actually_paid === 0) {
        // console.log('只有欠费订单')
        // Toast('只有欠费订单')
          this.$nextTick(() => {
            setTimeout(() => {
            //   只有欠费订单
              that.$dialog({
                title: '通知',
                message: `${this.plate_number}有${escData.total}笔欠费订单，请扫码补缴欠费订单离场`,
                confirmButtonColor: '#1989fa'
              // confirmButtonText: '去缴纳欠费'
              }).then(() => {
                this.$router.push({
                  path: '/payEscape',
                  query: {
                    pay_channel: this.pay_channel,
                    pay_channel_platform: this.pay_channel_platform,
                    sid: this.sid,
                    sign: this.sign,
                    escData: JSON.stringify(escData),
                    localInfo: JSON.stringify(localInfo)
                  }
                })
              })
            }, 100)
          })
        }
      }
    }

    this.tempQueryParams = {
      plate_number: this.plate_number,
      gross_price: parseFloat(this.gross_price),
      actually_paid: parseFloat(this.actually_paid),
      req_order_sn: this.req_order_sn,
      start_time: this.start_time,
      end_time: this.end_time,
      sign: this.sign,
      hour: this.hour,
      min: this.min,
      seconds: this.seconds,
      local_info: this.local_info,
      sid: this.sid,
      appid: this.appid,
      auth_code: this.saveRouteQueryDataLocal.auth_code
    }

    let payWay = common.payWay.weichat
    if (common.getUserAgent() === 'wx') {
      payWay = common.payWay.weichat
    } else if (common.getUserAgent() === 'zfb') {
      payWay = common.payWay.alipay
    }
    // console.log('sidsid', this.sid)
    this.get_open_url(payWay, this.sid, this.plate_number, this.tempQueryParams).then((openUrl) => {
      // console.log('fffff')

      if (this.actually_paid > 0) {
        this.getAllCode()
      } else if (this.actually_paid === 0 && escData.total > 0) {
        this.getAllCode()
      } else if (this.actually_paid === 0) {
        if (this.discount_coupon_ids_send.length > 0) {
          this.getAllCode()
        }
      }
      this.get_coupon()

      // console.log('isTypeReady', this.isTypeReady)
      // console.log('isVueReady', this.isVueReady)
      // console.log('isPress', this.isPress)
      // console.log('actually_paid', this.actually_paid)
      // console.log('code', this.code)
    })

    const that = this
    this.isVueReady = true
    // console.log('微信还是支付宝', common.getUserAgent())
    if (common.getUserAgent() === 'wx') {
      this.WX_Bridge_ready(() => {
        setButtonClertload()
      })
    } else if (common.getUserAgent() === 'zfb') {
      this.ZFBReady(() => {
        setButtonClertload()
      })
    }

    function setButtonClertload () {
      that.$nextTick(() => {
        that.isTypeReady = true
      })
    }
    this.getTempBackTimeout()
    this.$store.commit(constant.keyCodeVuex, this.code)
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'payEscape') {
      next()
    } else {
      this.unsubscribeAndClose()
      next()
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainBox {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container {
  margin-top: 0.22rem;
  padding: 0 0.3rem;
}

.mask_layout {
  z-index: 9999;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.row1 {
  display: flex;
  align-items: center;
  justify-content: left;
}

.row1 .number {
  font-size: 0.22rem;
  font-weight: bold;
  margin-right: 0.06rem;
}

.row1 .normal {
  font-size: 0.16rem;
  padding: 0.01rem 0.04rem;
  background: #f4f4f4;
  border-radius: 0.12rem;
}

.row1 .special {
  font-size: 0.16rem;
  padding: 0.01rem 0.04rem;
  color: #7eab4c;
  background: #e9f9dc;
  border-radius: 0.12rem;
}

.row2 {
  margin-top: 0.36rem;
  text-align: center;
  font-size: 0.16rem;
}

.row3 {
  margin-top: 0.12rem;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.row3 .code {
  font-size: 0.28rem;
  color: #333;
  font-weight: bold;
  margin-right: 0.06rem;
}

.row3 .number {
  font-size: 0.42rem;
  color: #333;
  font-weight: bold;
}

.row4 {
  margin-top: 0.24rem;
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.01rem solid #ededed;
  border-bottom: 0.01rem solid #ededed;
}

.row4 .time-tip {
  color: #999;
  font-size: 0.14rem;
}

.row4 .in-time {
  color: #333;
  font-size: 0.16rem;
}

.row5,
.row6 {
  margin-top: 0;
  border-top: none;
}

.row5 > .couponPrice {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  font-weight: bold;
  color: rgba(238, 38, 10, 1);
}
.couponPrice img {
}

.row6 > .time-tip {
  font-size: 0.14rem;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
}

.row6 {
  border-bottom: none;
  margin-top: 23px;
}

.row6 > .price {
  font-size: 0.24rem;
  font-weight: bold;
  color: rgba(255, 62, 0, 1);
}

.button-row-pay {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  font-size: 0.1rem;
}

.dialogBox {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background: #fff;
  padding: 0.2rem 0.12rem 0;
  box-sizing: border-box;
  z-index: 9000;
}

.dialogHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.16rem;
  margin-bottom: 0.18rem;
  font-weight: bold;
}

.dialogHead > img {
  width: 0.2rem;
  height: 0.2rem;
}

.listBox {
  height: 72%;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
}

.listBox::-webkit-scrollbar {
  display: none;
}

.dialogList {
  display: flex;
  align-items: center;
  border: 1px solid #d5d5d5;
  height: 0.94rem;
  margin-bottom: 0.1rem;
}

.dialogList > div:first-child {
  color: #ee260a;
  font-size: 0.18rem;
  width: 33%;
  text-align: center;
}

.dialogList > div:first-child span {
  font-size: 0.32rem;
}

.dialogList > div:nth-child(2) {
  height: 0.6rem;
  border-left: 1px solid rgba(228, 223, 223, 1);
  margin-right: 0.18rem;
}

.dialogList > div:nth-child(3) {
  margin-right: 0.1rem;
}

.dialogList > div:nth-child(3) > div:first-child {
  font-size: 0.14rem;
  color: #333333;
  margin-bottom: 0.04rem;
  font-weight: bold;
  margin-left: .2667rem;
}

.dialogList > div:nth-child(3) > div {
  /* margin-bottom: 0.06rem; */
  margin-bottom: 0.025rem;
  color: #909090;
  text-align: left;
}
.dialogList .showBizName{
  font-size: 0.12rem;
  color: #333333;
  margin-left: 5px;
}

.dialogBTN {
  width: 3.17rem;
  height: 0.5rem;
  background: rgba(71, 151, 234, 1);
  border-radius: 2px;
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 0.5rem;
  text-align: center;
  margin: 0.18rem auto 0;
}

/* add */
.commentBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.01rem solid #ededed;
  padding: 0.15rem 0;
  /* border-bottom: none; */
  /* height: 1rem ; */
  /* height: 100%; */
  /* line-height: .5rem; */
}
.comment-time-tip {
  color: #999;
  font-size: 0.14rem;
}
.comment-in-time {
  max-width: 250px;
  text-align: right;
  line-height: 0.2rem;
  font-size: 0.14rem !important;
}
</style>
